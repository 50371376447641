<div class="tw-h-full">
  <div>
    <app-header></app-header>
  </div>
  <div class="router-content">
    @if (badgeDisabled) { <app-skeleton class="step-description"></app-skeleton>}

    <router-outlet></router-outlet>
  </div>
  <div class="router-footer">
    <app-footer></app-footer>
  </div>
</div>
<ngx-spinner type="ball-scale-multiple"></ngx-spinner>
