import { Component, OnInit, ViewEncapsulation, effect } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { HeaderComponent } from "./core/components/header/header.component";
import { FooterComponent } from "./core/components/footer/footer.component";
import { LoadingService } from "./shared/services/loading.service";
import { NgxSpinnerModule } from "ngx-spinner";
import { SkeletonComponent } from "./shared/components/skeleton/skeleton.component";
SkeletonComponent;

@Component({
  selector: "app-root",
  standalone: true,
  imports: [RouterOutlet, HeaderComponent, FooterComponent, NgxSpinnerModule, SkeletonComponent],
  providers: [],
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  title = "sellit9-confirmation";
  translateData: any;
  badgeValue: number = 0;
  badgeDisabled: boolean = true;
  constructor(public loadingService: LoadingService) {
    effect(() => {
      this.badgeDisabled = this.loadingService.loading();
    });
  }
  ngOnInit(): void {}
}
