import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from "@angular/core";
import { provideRouter } from "@angular/router";

import { routes } from "./app.routes";
import { provideClientHydration } from "@angular/platform-browser";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { HttpClient, HttpClientModule, provideHttpClient, withFetch } from "@angular/common/http";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { catchError, of } from "rxjs";
import { OrdersService } from "./shared/services/orders.service";
import { ErrorHandler } from "@angular/core";
import * as Sentry from "@sentry/angular-ivy";
import { SanityService } from "./shared/services/sanity.service";

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideClientHydration(),
    provideHttpClient(withFetch()),
    provideAnimationsAsync(),
    importProvidersFrom(HttpClientModule),
    importProvidersFrom(
      TranslateModule.forRoot({
        defaultLanguage: "en",
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      })
    ),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [SanityService],
      multi: true,
    },
    { provide: OrdersService },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
  ],
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./confirmation_assets/i18n/", ".json");
}

export function initApp(http: HttpClient, translate: TranslateService) {
  return () =>
    new Promise<boolean>((resolve: (res: boolean) => void) => {
      const defaultLocale = "en";

      http
        .get(`/confirmation_assets/i18n/en.json`)
        .pipe(catchError(() => of(null)))
        .subscribe((devKeys: any) => {
          translate.setTranslation(defaultLocale, devKeys || {});

          translate.setDefaultLang(defaultLocale);
          translate.use(defaultLocale);

          resolve(true);
        });
    });
}

export function initializeApp(sanityService: SanityService) {
  return () =>
    new Promise<void>((resolve, reject) => {
      sanityService
        .fetchData()
        .then((data) => {
          sanityService.setData(data);
          resolve();
        })
        .catch((error) => {
          console.error("Error fetching data from Sanity", error);
          reject(error);
        });
    });
}
