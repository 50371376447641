import { Routes } from "@angular/router";
import { GetOrderGuard } from "./shared/guards/order-by-id-and-token.guard";
export const routes: Routes = [
  { path: "", redirectTo: "confirmation", pathMatch: "full" },
  { path: "confirmation", redirectTo: "confirmation", pathMatch: "full" },
  {
    path: "confirmation",
    loadChildren: () => import("./feature/feature.routes").then((mod) => mod.featureRoutes),
    canActivate: [GetOrderGuard],
  },
  { path: "**", redirectTo: "confirmation", pathMatch: "full" },
];
