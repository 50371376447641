import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { catchError, from, map, Observable, of } from "rxjs";
import { OrdersService } from "../services/orders.service";
import { LoadingService } from "../services/loading.service";

import { isPlatformBrowser } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class GetOrderGuard implements CanActivate {
  constructor(private router: Router, private ordersService: OrdersService, private loadingService: LoadingService, @Inject(PLATFORM_ID) private platformId: object) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const orderId = route.queryParams["orderId"];
    const token = route.queryParams["token"];

    // Allow navigation if token or orderId is missing
    if (!token || !orderId) {
      if (isPlatformBrowser(this.platformId)) {
        window.location.href = "https://www.sellit9.com/404";
      }
      return of(false);
    }

    // Fetch order data
    return from(this.ordersService.getOrderByIdAndToken(orderId, token)).pipe(
      map((hasAccess) => {
        if (hasAccess && hasAccess.short_id === orderId) {
          route.data = { order: hasAccess };

          this.loadingService.setLoading(false);
          return true;
        } else {
          if (isPlatformBrowser(this.platformId)) {
            window.location.href = "https://www.sellit9.com/404";
          }
          return false; // Deny navigation
        }
      }),
      catchError((error) => {
        console.error("Error occurred:", error);
        if (isPlatformBrowser(this.platformId)) {
          window.location.href = "https://www.sellit9.com/404";
        }
        return of(false); // Deny navigation
      })
    );
  }
}
