<nav class="navbar tw-flex tw-items-center tw-justify-center">
  <a
    (click)="getBack()"
    class="navbar__logo tw-text-white tw-font-bold tw-text-xl tw-cursor-pointer"
  >
    <img
      src="https://assets-global.website-files.com/6569026f2b8bf7049365a2cd/6569227467bcf66aeb3f2d03_logo.svg"
      width="129"
      loading="lazy"
      alt="Sellit9"
    />
  </a>
  <input
    type="checkbox"
    id="menu-toggle"
    class="navbar__toggle-checkbox tw-hidden"
  />
  <label
    for="menu-toggle"
    class="navbar__toggle-label tw-block tw-text-white tw-cursor-pointer tw-absolute tw-right-0"
  >
    <svg
      width="27"
      height="11"
      viewBox="0 0 27 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26 10L1 10"
        stroke="#19A45D"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M26 1L1 0.999998"
        stroke="#19A45D"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  </label>
  <div class="navbar__menu tw-hidden tw-flex tw-items-center" id="menu-items">
    <ul class="navbar__items tw-text-sm tw-flex-grow tw-list-none">
      @for(menu of sanityMenuData.menu;track menu){
        <li>
          <a
            [href]="menu.link"
            class="navbar__link tw-block tw-no-underline {{menu.class}}"
            >{{menu.name}}</a
          >
        </li>
      }
    </ul>
    <label
      for="menu-toggle"
      class="navbar__close-label tw-block tw-text-white tw-cursor-pointer"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="tw-h-6 tw-w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </label>
  </div>
</nav>

<div
  id="overlay"
  class="overlay tw-hidden tw-fixed tw-top-0 tw-left-0 tw-w-full tw-h-full tw-bg-black tw-opacity-50"
></div>
