import { Injectable, OnInit, signal } from '@angular/core';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class DataTransferService implements OnInit{
  count = signal<number>(0);
  constructor(public localStorageSerice:LocalStorageService){
    
    if(this.localStorageSerice.getItem('device')){
      this.count.set(this.localStorageSerice.getItem('device').length)
    }
  }
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }
  
  updateCount(): void{
    if(this.localStorageSerice.getItem('device')){
      this.count.set(this.localStorageSerice.getItem('device').length)
    }
  }
}
