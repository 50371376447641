import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation, Inject, PLATFORM_ID } from "@angular/core";
import { Router, RouterLink, ActivatedRoute, Params } from "@angular/router";
import { DialogService, DynamicDialogModule, DynamicDialogRef } from "primeng/dynamicdialog";
import { BadgeModule } from "primeng/badge";
import { DataTransferService } from "../../../shared/services/data-transfer.service";
import { CommonModule } from "@angular/common";
import { SanityService } from "../../../shared/services/sanity.service";

@Component({
  selector: "app-header",
  standalone: true,
  imports: [RouterLink, DynamicDialogModule, BadgeModule, CommonModule],
  providers: [DialogService],
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
  ref: DynamicDialogRef | undefined;
  badgeValue: number = 0;
  badgeDisabled: boolean = false;
  queryParams!: Params;
  paramOption: string = "";
  sanityMenuData: any;
  constructor(
    public cd: ChangeDetectorRef,
    public dialogService: DialogService,
    public dataTransferService: DataTransferService,
    @Inject(PLATFORM_ID) private platformId: object,
    public router: Router,
    public route: ActivatedRoute,
    private sanityService: SanityService,

    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.sanityMenuData = this.sanityService.getData().filter((data: any) => data._type == "confirmation_menu" && data?.slug?.current == "main-menu")[0];
    this.badgeValue = this.dataTransferService.count();
    this.route.queryParams.subscribe((queryParams) => {
      this.paramOption = queryParams["option"];
      this.queryParams = queryParams;
    });
  }

  getBack() {
    let baseUrl = "https://www.sellit9.com/sell/";
    const queryParams = new URLSearchParams(window.location.search);

    const existingOption = queryParams.get("option");
    const source = queryParams.get("utm_source");
    const medium = queryParams.get("utm_medium");
    const campaign = queryParams.get("utm_campaign");
    const content = queryParams.get("utm_content");
    const term = queryParams.get("utm_term");

    const params: string[] = [];

    if (existingOption) {
      params.push(`option=${encodeURIComponent(existingOption)}`);
    }
    if (source) {
      params.push(`utm_source=${encodeURIComponent(source)}`);
    }
    if (medium) {
      params.push(`utm_medium=${encodeURIComponent(medium)}`);
    }
    if (campaign) {
      params.push(`utm_campaign=${encodeURIComponent(campaign)}`);
    }
    if (content) {
      params.push(`utm_content=${encodeURIComponent(content)}`);
    }
    if (term) {
      params.push(`utm_term=${encodeURIComponent(term)}`);
    }

    if (params.length > 0) {
      baseUrl += `?${params.join("&")}`;
    }

    window.location.href = baseUrl;
  }
}
